@value SiteLightBlue, HoverLightBlue, SiteLightGrey, SiteDarkGrey from '../../../../assets/css/colors.css';

.outline {
  background-color: transparent;
}

.ContainerButton {
	width: 100px;
	height: 100px;
	border: none;
	color: inherit;
	background: none;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 300;
	outline: none;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	border: 2px solid HoverLightBlue;
	border-radius: 50%;
}

.ContainerButton:hover > span {
	transform: scale(1.3);
}

.ContainerButton span {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	color: HoverLightBlue;
}

.ContainerButton::after {
	content: '';
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.ContainerButton:hover {
	background-color: SiteLightBlue;
	border-color: SiteLightBlue;
	transition: ease-in-out 300ms;
}

.ContainerButton:hover span {
	color: white;
}

.Disabled {
	width: 100px;
	height: 100px;
	border: none;
	color: inherit;
	background: none;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 300;
	outline: none;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	border: 2px solid lightgray;
	background-color: white !important;
	border-radius: 50%;
}

.Disabled span {
	color: lightgray;
}
