.Main {
  width: 100%;
  padding: 15px;
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
  background-color: #ef7c00;
  font-weight: bold;
  color: white;
  min-height: 70px;
}

.Link {
  text-decoration: underline;
  color: white;
  font-weight: normal;
}

.CloseButton {
  cursor: pointer;
  font-size: 20px;
}
