@value SiteDarkGrey, SiteLightGrey, SiteDarkBlue, SiteOrange from '../../../assets/css/colors.css';

.RegisterSection {
  margin-top: 20px;
}

.RegisterButton {
  margin-top: 20px;
  width: 250px;
  background-color: SiteOrange;
  border-color: SiteOrange;
  color: white;
}

.LoginHint {
  text-align: center;
  margin-top: 15px;
  max-width: 210px;
}

.LoginButton {
  border-color: SiteDarkBlue;
  color: SiteDarkBlue;
}
