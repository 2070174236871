@value StatusOrange, SiteOrange from '../../../../assets/css/colors.css';

.Button {
  background-color: StatusOrange;
  border-color: StatusOrange;
}

.Button:hover {
	background-color: SiteOrange;
	border-color: SiteOrange;
	transition: ease-in-out 300ms;
}