@value StatusGreen, StatusOrange, StatusRed, StatusGrey from '../../../assets/css/colors.css';

.Main {
	border-radius: 50%;
	background-color: StatusGreen;
	width: 25px;
	height: 25px;
}

.Main > * {
	padding: 6px;
	color: white;
	font-size: 12px;
}

.Green {
	background-color: StatusGreen;
}

.Orange {
	background-color: StatusOrange;
}

.Red {
	background-color: StatusRed;
}

.Grey {
	background-color: StatusGrey;
}

.MainFlex {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-radius: 0%;
	height: 100%;
	width: 25px;
}

.Text {
	color: StatusGrey;
	font-size: 12px;
	text-transform: uppercase;
	white-space: nowrap;
}