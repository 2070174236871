@value SiteDarkGrey from '../../../assets/css/colors.css';

.Selected {
  border: 2px solid black;
}

.SelectedNoFilter {
  border: 2px solid black !important;
  background-color: gray !important;
  color: white !important;
}

.ButtonSmall {
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.25rem;
  min-height: 30px;
  min-width: 55px;
  cursor: pointer;
  border: 1px solid lightgray;
  /* margin: 10px 0; */
  color: lightgray;
  background-color: white;
}

.TextSmall {
  align-self: center;
  text-align: center;
  font-size: 14px;
  color: white;
  width: 100%;
}

.Badge {
  padding-top: 8px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.BadgeIcon {
  padding-top: 8px;
  padding-bottom: 5px;
  padding-right: 5px;
  background-color: white;
  color: black;
}

.Caret {
  padding: 0px !important;
  box-sizing: border-box;
  color: #676869;
  background-color: #fff !important;
  border-radius: 0 2px 2px 0;
  border: 0px;
}

.Caret:hover {
  padding: 0px !important;
  box-sizing: border-box;
  color: #fff;
  background-color: SiteDarkGrey !important;
  border-radius: 0 2px 2px 0;
  transition: ease-in 300ms;
  border: 0px;
}

.CaretArrow {
  height: 25px;
  width: 30px;
  text-align: center;
}
