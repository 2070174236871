@value SiteOrange, SiteLightGrey from '../../../assets/css/colors.css';

.Main {
	display: flex;
	justify-content: center;
	align-items: center;
}

.MainPadding {
	padding-top: 10%;
}

.Orange {
	color: SiteOrange;
}

.Gray {
	color: SiteLightGrey;
}