@value SiteDarkGrey, SiteDarkBlue, SiteOrange from '../../assets/css/colors.css';

/* indie-flower-regular - latin */
@font-face {
  font-family: "Indie Flower";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/indie-flower-v17-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../assets/fonts/indie-flower-v17-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/indie-flower-v17-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../assets/fonts/indie-flower-v17-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../assets/fonts/indie-flower-v17-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../assets/fonts/indie-flower-v17-latin-regular.svg#IndieFlower")
      format("svg"); /* Legacy iOS */
}

.Main {
  background-image: url("../../assets/images/paperplane_2000.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.TextSection {
  padding: 40px 50px 40px 50px;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  font-size: 14px;
}

.TextSection > div {
  display: flex;
  flex-flow: column;
  width: 100%;
  align-items: center;
  line-height: 23px;
  align-content: center;
}

.TextSection p {
  padding: 0px;
}

.TextSection h1 {
  text-align: center;
}

.Search {
  margin: 0;
  padding: 20px 40px;
  list-style: none;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  height: 100%;
  font-size: 12px;
}

.Header {
  text-transform: none;
  padding: 8px;
}

.LogosSection {
  text-align: center;
  margin-bottom: 50px;
}

.LogosHint {
  display: flex;
  justify-content: center;
  color: gray;
  font-size: 1.2rem;
}

.VerlagSection {
  background-color: #eeeeed;
}

.VorteileSection {
  background-color: SiteDarkBlue;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
}

.SpecialCampaign {
  font-size: 60px;
  color: SiteDarkBlue;
  font-family: "Indie Flower", cursive;
  text-transform: capitalize;
}

.CampaignContainer {
  padding-top: 15px;
  padding-bottom: 50px;
}

.PricingSection {
  background-color: SiteDarkBlue;
  color: white;
}

.TriangleDiv {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.TriangleDown {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 50px solid SiteDarkBlue;
  flex-flow: column;
  align-items: center;
}
.Prices{
  display: flex;

}