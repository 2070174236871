.Auth {
  margin: 20px auto;
  width: 80%;
  text-align: center;
  box-shadow: 0 2px 3px #ccc;
  border: 1px solid #eee;
  padding: 10px;
  box-sizing: border-box;
}

.Logo {
  margin: 20px auto;
  width: 80%;
  text-align: center;
  padding: 10px;
}

@media (min-width: 600px) {
  .Auth,
  .Logo {
    width: 500px;
  }
}

.Title {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.Error {
  color: red;
  font-weight: bold;
}
