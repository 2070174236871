@value SiteDarkGrey from '../../../assets/css/colors.css';

.Footer {
	width: 100%;
	background-color: SiteDarkGrey;
	box-sizing: border-box;
	/* z-index: 2; */
}

.Footer nav {
	height: 120px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-top: 10px;
}

.Footer ul {
	white-space: nowrap;
	width: 25%;
	padding: 0px 20px;
}

@media (max-width: 800px) {
	.Footer ul {
		white-space: nowrap;
		width: 100%;
	}
}

.Footer ul:last-child {
	padding-right: 0;
}

.Footer li {
	margin: 0;
	padding: 2px;
	list-style: none;
	align-items: left;
	height: 100%;
	color: white;
}

.Footer li span {
	padding: 0 5px;
}

.Footer a,
.Footer a:hover,
.Footer a:active,
.Footer a.active {
	color: white;
	text-decoration: none;
}

.Footer nav > div {
	display: flex;
	flex-direction: column;
}

.MadeIn {
	width: 100%;
	height: 50px;
	text-align: center;
	padding: 15px 10px;
	background-color: black;
	color: white;
	font-size: 12px;
	text-transform: uppercase;
}

.MadeIn:hover {
	text-decoration: underline;
}

.Link {
	cursor: pointer;
	background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
	color: white;
	outline:none;
}

.Link:hover,
.Link:focus {
	text-decoration: none;
	outline:none;
}