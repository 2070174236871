.triangleDown {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 100px solid red;
}

.Header {
  text-transform: none;
  padding: 8px;
}
