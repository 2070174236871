@value SiteDarkGrey, SiteLightGrey, SiteDarkBlue, SiteOrange from '../../../assets/css/colors.css';

.StatisticSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 55px;
}

.StatisticBox {
  height: 120px;
  width: 120px;
  padding: 35px 20px 20px 20px;
  background-color: SiteDarkBlue;
  margin: 20px 10px;
  color: white;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.Number {
  font-size: 20px;
}
