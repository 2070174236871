@value SiteDarkBlue from '../../../assets/css/colors.css';

.Main {
  font-size: 1rem;
  display: flex;
  justify-content: space-evenly;
  background-color: #A8D5BA;
  font-weight: bold;
  color: white;
  min-height: 40px;
  align-items: center;
  text-align: center;
  margin-top: -18px;

  
}

.Link {
  text-decoration: underline;
  color: white;
  font-weight: normal;
}

.CloseButton {
  cursor: pointer;
  font-size: 16px;
  align-self: flex-end;
  background-color: SiteDarkBlue;
  text-align: center;
  border-radius: 50%;
  border: 2px solid white;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.CloseDiv {
}

.CloseDiv:last-of-type {
  margin-left: auto;
}

.DiscoverButton {
  border: 2px solid white;
  color: white;
  margin: 5px;
}
