@value SiteLightGrey, SiteDarkGrey from '../../../../assets/css/colors.css';

.Button {
  background-color: SiteLightGrey;
	border-color: SiteLightGrey;
	width: 100%;
}

.Button:hover {
	background-color: SiteDarkGrey;
	border-color: SiteDarkGrey;
	transition: ease-in-out 300ms;
}