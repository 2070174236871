@value SiteLightBlue, HoverLightBlue from '../../../../assets/css/colors.css';

.Button {
  background-color: SiteLightBlue;
  border-color: SiteLightBlue;
  cursor: pointer;
  width: 100%;
}

.outline {
  background-color: transparent;
  color: SiteLightBlue;
}

.Button:hover {
  background-color: HoverLightBlue;
  border-color: HoverLightBlue;
  transition: ease-in-out 300ms;
  cursor: pointer;
}

.Button div,
.Button label {
  cursor: pointer;
}

.Button a {
  color: white;
  text-decoration: none;
}

.FloatingButton {
  background-color: HoverLightBlue;
  border-color: HoverLightBlue;
  padding: 20px;
  text-transform: uppercase;
}

.FloatingButton:hover {
  background-color: SiteLightBlue;
  border-color: SiteLightBlue;
  transition: ease-in-out 300ms;
}
