.Row{
  width: 50%;
  margin-left: 0px;
  margin-right: 0px;
}


.Header {
  text-transform: none;
  padding: 8px;
  text-align: center;
}

.Icons {
  color: #eeeeed;
}

.PricingText {
  padding: 5px 30%;
  font-size: 14px;
}

.PricingSubText {
  padding: 20px 30%;
  color: grey;
  font-size: 12px;
}

.PricingColumn {
  padding-bottom: 70px;
}

.Header {
  text-transform: none;
  padding: 8px;
}
