.Main {
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  background-color: #0b3658;
  color: white;
}

.Link {
  text-decoration: underline;
  color: white;
  font-weight: normal;
}
.Link:hover {
  color: white;
}

.Text {
  margin-right: 20px;
}

.CloseButton {
  cursor: pointer;
  font-size: 20px;
}

.RequestButton {
  margin-left: 20px;
  margin-right: 20px;
  color: white;
  border-color: white;
  text-transform: uppercase;
}
