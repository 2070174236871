@value SiteLightBlue, HoverLightBlue, SiteLightGrey, SiteDarkGrey from '../../../../assets/css/colors.css';

.outline {
  background-color: transparent;
}

.AnimatedContainerButton {
	width: 100px;
	height: 100px;
	border: none;
	color: inherit;
	background: none;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 300;
	outline: none;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	border: 2px solid rgb(18, 172, 75);
	border-radius: 50%;
}

.AnimatedContainerButton:hover > span {
	transform: scale(1.3);
}

.AnimatedContainerButton span {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	color: rgb(18, 172, 75);
}

.AnimatedContainerButton:hover {
	background-color: green;
	border-color: green;
	transition: ease-in-out 300ms;
}

.SecondaryFloatingButton:hover span {
	color: white;
}

.AnimatedContainerButtonSent {
	width: 100px;
	height: 100px;
	border: none;
	color: inherit;
	background: none;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 300;
	outline: none;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	border: 2px solid rgb(18, 172, 75);
	border-radius: 50%;
	background-color: white !important;
}

.AnimatedContainerButtonSent:active {
	background-color: white !important;
}

.AnimatedContainerButtonSent span {
	transition: all 0.1s ease-in-out	;
	animation: flyingJob 1s;
}

@keyframes flyingJob {
	0% {
		transform: translate(-35px, 40px);
	}
	25% {
		transform: translate(-10px, 0px);
	}
	50% {
		transform: translate(15px, -20px);
	}
	75% {
		transform: translate(25px, -30px);
	}
	100% {
		transform: translate(35px, -30px);
		opacity: 0;;
	}
}

.AnimatedContainerButtonSent span {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	color: rgb(18, 172, 75);
}

.Disabled,
.AnimatedContainerButton:disabled {
	width: 100px;
	height: 100px;
	border: none;
	color: inherit;
	background: none;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 300;
	outline: none;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	border: 2px solid lightgray;
	background-color: white !important;
	border-radius: 50%;
}

.Disabled span,
.AnimatedContainerButton:disabled span {
	color: lightgray;
}

.FlyingButtonDisabled {
	width: 100px;
	height: 100px;
	border: none;
	color: inherit;
	background: none;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 300;
	outline: none;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	border: 2px solid lightgray;
	background-color: white !important;
	border-radius: 50%;
}

.FlyingButtonDisabled span {
	color: lightgray;
}