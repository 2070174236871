@value SiteDarkGrey, SiteLightGrey, SiteDarkBlue, SiteOrange, HoverGrey from '../../../../assets/css/colors.css';

.Version {
  font-size: 0.8rem;
  color: SiteLightGrey;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.VersionLink {
  margin-top: 17px;
  color: inherit;
  font-size: 0.9rem;
}

.VersionLink:hover {
  color: inherit;
}

.Link {
  cursor: pointer;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: SiteLightGrey;
  outline: none;
}

.Link:hover,
.Link:focus {
  text-decoration: none;
  outline: none;
}

.LanguageItem {
  padding: 15px;
  font-size: 1rem;
}

.LanguageItem:hover {
  background-color: HoverGrey;
  cursor: pointer;
}
