/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/open-sans-v34-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/fonts/open-sans-v34-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/open-sans-v34-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("assets/fonts/open-sans-v34-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/open-sans-v34-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("assets/fonts/open-sans-v34-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}

html,
body {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  background: #fff;
}

label {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

.container {
  width: 100%;
  max-width: 100%;
}
