@value SiteDarkGrey, SiteLightGrey, SiteDarkBlue, SiteOrange from '../../../assets/css/colors.css';

.Main * {
  margin: auto;
  max-width: 600px;
}

.Textbox {
  border-color: SiteOrange;
  background-color: #f8f8f8;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  height: 50px;
}

.Button,
.Button:hover {
  background-color: SiteOrange;
  border-color: SiteOrange;
  width: 40px;
  height: 50px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.Button:focus,
.Textbox:focus {
  border-color: SiteOrange;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px SiteOrange;
  outline: 0 none;
  width: 40px;
  border-radius: 8px;
}
