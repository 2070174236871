@value SiteDarkGrey, SiteLightGrey, SiteDarkBlue, SiteOrange, HoverGrey from '../../../assets/css/colors.css';

.NavBar {
  padding: 0;
  height: 80px;
  align-items: stretch;
  background-color: black;
}

.NavItemVisited:nth-child(5),
.NavItem:nth-child(5) {
  background-color: SiteDarkGrey;
}

.NavItemVisited:nth-child(6),
.NavItem:nth-child(6) {
  background-color: SiteDarkBlue;
}

.NavItem * {
  align-self: flex-end;
  color: SiteLightGrey !important;
  padding-bottom: 15px;
}

.NavItemVisited * {
  align-self: flex-end;
  color: white !important;
  padding-bottom: 15px;
  font-weight: bold;
}

.NavItemVisited > span,
.NavItem > span {
  margin: 0 0.2em 0 1.5em;
}

.NavItemVisited:hover *,
.NavItem:hover * {
  color: SiteOrange !important;
}

@media (max-width: 1025px) {
  .NavItemVisited,
  .NavItem {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 20px 10px;
  }
}

@media (min-width: 1025px) {
  .NavItemVisited,
  .NavItem {
    display: flex;
    flex-direction: row;
    align-self: center;
    height: 100%;
    padding-bottom: 7px;
  }
}

.NavLink,
.NavItem a,
.NavItem a span {
  padding: 0;
  height: 32px;
  text-transform: uppercase;
  color: SiteLightGrey;
}

.NavItemVisited a,
.NavItemVisited a span {
  padding: 0;
  height: 32px;
  text-transform: uppercase;
  color: white !important;
}

/* Raw CSS */
:global(.container-fluid) {
  padding-right: 0px;
}

:global(.navbar-collapse) {
  z-index: 999;
}

:global(a.nav-link) {
  color: SiteDarkGrey !important;
}

:global(a.nav-link:hover) {
  color: SiteOrange !important;
}

:global(.navbar-expand-lg .navbar-nav .nav-link) {
  padding-left: 0.3rem;
  padding-right: 0.8rem;
}

:global(.navbar-dark .navbar-nav .nav-link:hover) {
  color: SiteOrange;
}

:global(.navbar-expand-lg .navbar-nav) {
  height: 100%;
}

@media (min-width: 1025px) {
  :global(.navbar-collapse),
  :global(.navbar-collapse > ul) {
    padding: 0;
  }

  :global(.navbar-nav) {
    height: 80px;
  }
}

@media (max-width: 1024px) {
  :global(.navbar-nav) {
    background-color: black;
  }
}

.Version {
  font-size: 0.8rem;
  color: SiteLightGrey;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.VersionLink {
  margin-top: 17px;
  color: inherit;
  font-size: 0.9rem;
}

.VersionLink:hover {
  color: inherit;
}

.UserMenuNavItem {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 0px;
}

.UserMenuNavLink {
  color: SiteDarkGrey;
  text-transform: uppercase;
}

.NavItemLogin {
  background-color: SiteDarkBlue;
}

.NavItemService {
  background-color: SiteDarkGrey;
}
