@value SiteDarkGrey, SiteLightGrey, SiteOrange, StatusGreen, HoverGrey from '../../../assets/css/colors.css';

.Header {
  color: SiteDarkGrey;
  background-color: none;

}

.modalDialog{
  max-width:unset;
  max-height: unset;
  text-align: center;
  max-width: fit-content;
  max-height: fit-content;
  margin-left: auto;
  margin-right: auto;
  height: 94%;
  display: flex;
  align-items: center;

}

