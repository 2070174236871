.Icons {
  color: #eeeeed;
}

.VerlagText {
  text-align: left;
  font-size: 14px;
}

.VerlagColumn {
  text-align: center;
  padding: 40px;
}

.Header {
  text-transform: none;
  padding: 8px;
}
