@value SiteLightGrey, SiteDarkGrey from '../../../assets/css/colors.css';

:global(.page-link),
:global(.page-item.disabled .page-link) {
  border-color: SiteLightGrey;
  color: SiteDarkGrey !important;
}

:global(.page-item.active .page-link) {
  background-color: SiteLightGrey;
  border-color: SiteLightGrey;
  color: SiteDarkGrey;
}

:global(.page-link:hover),
:global(.page-item:hover .page-link) {
  background-color: SiteDarkGrey;
  color: white !important;
}

:global(.page-item:hover) {
  color: white;
}
