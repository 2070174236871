@value SiteDarkGrey, SiteLightGrey from '../../../assets/css/colors.css';

.Main {
	box-sizing: border-box;
	width: 100%;
	border: 2px solid #ccc;
	vertical-align: middle;
	border-radius: 5px;
	cursor: pointer;
  -webkit-transition: all 0.20s ease-in-out;
  -moz-transition: all 0.20s ease-in-out;
  -ms-transition: all 0.20s ease-in-out;
  -o-transition: all 0.20s ease-in-out;
}

.MainRequired {
	box-sizing: border-box;
	width: 100%;
	border: 2px solid #ccc;
	vertical-align: middle;
	border-radius: 5px;
	cursor: pointer;
  -webkit-transition: all 0.20s ease-in-out;
  -moz-transition: all 0.20s ease-in-out;
  -ms-transition: all 0.20s ease-in-out;
  -o-transition: all 0.20s ease-in-out;
	border-color: #dc3545;
}

.Main:active {
  box-shadow: 0 0 5px #007bff;
  border: 1px solid #007bff;
}

.Show {
	display: block;
}

.Hide {
	display: none;
}

.Disabled {
	background-color: lightgray;
}

.Display {
	width: 100%;
	background-color: white;
	box-sizing: border-box;
	display: flex;
	border-radius: 5px;

}

.Text {
	display: flex;
	float: left;
	position: relative;
	box-sizing: border-box;
	width: 100%;
	padding: 7px 5px;
}

.Label {
	display: flex;
	float: left;
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
}

.Caret {
	padding: 4px 5px;
	box-sizing: border-box;
	color: #676869;
	background-color: #fff;
	border-radius: 0 2px 2px 0;
}

.Caret:hover {
	padding: 4px 5px;
	box-sizing: border-box;
	color: #fff;
	background-color: SiteDarkGrey;
	border-radius: 0 2px 2px 0;
	transition: ease-in 300ms;
}

.CaretArrow {
	height: 25px;
	width: 30px;
	text-align: center;
}

.CaretDisabled {
	padding: 4px 5px;
	box-sizing: border-box;
	border-radius: 0 2px 2px 0;
	background-color: SiteDarkGrey;
	color: black;
}