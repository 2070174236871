@value SiteDarkGrey, SiteLightGrey, SiteLightBlue, SiteOrange, StatusGreen, HoverGrey from '../../../assets/css/colors.css';

.CompanySection {
  display: flex;
}

.CompanyIcon {
  padding: 35px;
  height: 150px;
  border: 3px solid whitesmoke;
  background-color: HoverGrey;
  color: white;
}

.CompanyInfo {
  padding: 20px;
}

.Checkbox {
  width: 20px;
  height: 20px;
  background-color: whitesmoke;
  border: 2px solid lightgrey;
  margin-right: 10px;
}

.CheckBoxText {
  margin: 5px 20px;
}

.TermsOfUse {
  color: SiteOrange;
  font-weight: bold;
  cursor: pointer;
}

.TermsOfUse:hover {
  text-decoration: underline;
}

.PrimaryButton {
  background-color: SiteLightBlue;
  border-color: SiteLightBlue;
  color: white;
}

.SecondaryButton {
  background-color: HoverGrey;
  border-color: HoverGrey;
  color: white;
}
