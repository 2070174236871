.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.switch-wrapper {
  position: relative;
}
 
.switch-wrapper > div {
  position: absolute;
}

