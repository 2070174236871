.ActionContainer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 10px;
}

.ActionContainer:hover {
  background-color: lightgray;
  font-weight: bold;
}

.Action {
  padding-left: 15px;
}

.Delete:hover {
  background-color: #ff6358;
  color: white;
}