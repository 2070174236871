@media (min-width: 1025px) {
  .NavBarBrand {
    padding: 25px 10px;
  }
}

@media (max-width: 1025px) {
  .NavBarBrand {
    padding: 10px 10px;
    margin: 15px 15px 0 15px;
  }
}
